import {
	Container,
	createStylesContext,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import { Layout } from "../../shared/HomePage/helpers";
import { HomePageProps } from "../../shared/HomePage/HomePage";
import { HomePageSectionRenderer } from "../../shared/HomePage/HomePageSectionRenderer";

const [HomeSectionsStylesProvider] = createStylesContext("HomeSections");

const isFullBleedSection = (s: Layout): boolean =>
	s.__typename === "SectionHomeFadeHeroRecord";

export const HomePage: ReactFC<HomePageProps> = ({
	teasers,
	crossPromoData, // TODO: will we reuse this?
	layout,
}) => {
	const allStyles = useMultiStyleConfig("HomeSections", {
		variant: "marketbrief",
	});

	const fullBleedSections = layout.filter(isFullBleedSection);

	const otherSections = layout.filter((s) => !isFullBleedSection(s));

	return (
		<HomeSectionsStylesProvider value={allStyles}>
			<Container px={0} data-datocms-noindex>
				<HomePageSectionRenderer
					teasers={teasers}
					layout={fullBleedSections}
				/>
			</Container>
			<Container
				border="1px solid transparent"
				data-datocms-noindex
				sx={allStyles.outerWrapper}
				className="inner-content-section"
			>
				<HomePageSectionRenderer
					teasers={teasers}
					layout={otherSections}
				/>
			</Container>
		</HomeSectionsStylesProvider>
	);
};
